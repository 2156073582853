export default {
  name: 'Timeline',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    events: {
      type: Array[Object],
      required: true
    }
  }
};