import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-745d480c"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "privacy-policy--wrapper"
};
const _hoisted_2 = {
  key: 0
};
const _hoisted_3 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Questions = _resolveComponent("Questions");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [$data.PrivacyPolicy.title ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString($data.PrivacyPolicy.title), 1)) : _createCommentVNode("", true), $data.PrivacyPolicy.subTitle ? (_openBlock(), _createElementBlock("h2", _hoisted_3, _toDisplayString($data.PrivacyPolicy.subTitle), 1)) : _createCommentVNode("", true), _createVNode(_component_Questions, {
    questions: $data.PrivacyPolicy.questions
  }, null, 8, ["questions"])]);
}