import Partner from '@/content/BecomePartner';
import PartnerHeader from '@/components/BecomePartner/PartnerHeader';
import UspCards from '@/components/UspCards';
import Timeline from '@/components/Timeline';
export default {
  name: 'Partner',
  components: {
    PartnerHeader,
    UspCards,
    Timeline
  },

  data() {
    return {
      Partner
    };
  }

};