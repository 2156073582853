import FAQ from '@/content/FAQ';
import Questions from '@/components/Questions';
export default {
  name: 'FAQ',
  components: {
    Questions
  },

  data() {
    return {
      FAQ
    };
  }

};