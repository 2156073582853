import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-68026bc8"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "background-color"
};
const _hoisted_2 = {
  class: "timeline--wrapper"
};
const _hoisted_3 = {
  class: "timeline--content"
};
const _hoisted_4 = {
  class: "timeline--content-item--text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("h3", null, _toDisplayString($props.title), 1), _createElementVNode("p", null, _toDisplayString($props.subTitle), 1), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.events, event => {
    return _openBlock(), _createElementBlock("figure", {
      key: event.title,
      class: "timeline--content-item"
    }, [_createElementVNode("i", {
      class: _normalizeClass(["timeline--content-item--icon", event.icon])
    }, null, 2), _createElementVNode("figcaption", _hoisted_4, [_createElementVNode("h4", null, _toDisplayString(event.title), 1), _createElementVNode("p", null, _toDisplayString(event.content), 1)])]);
  }), 128))])])]);
}