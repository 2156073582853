import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-c377d8ba"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "questions--wrapper"
};
const _hoisted_2 = {
  class: "questions--sections--header"
};
const _hoisted_3 = {
  class: "questions--sections--header--title"
};
const _hoisted_4 = {
  key: 1
};
const _hoisted_5 = {
  key: 0,
  class: "questions--sections--header--subtitle"
};
const _hoisted_6 = ["onClick"];
const _hoisted_7 = {
  key: 0,
  class: "icon el-icon-close"
};
const _hoisted_8 = {
  key: 1,
  class: "icon el-icon-plus"
};
const _hoisted_9 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.questions.sections, (section, sectionIndex) => {
    return _openBlock(), _createElementBlock("div", {
      key: section.title,
      class: "questions--sections"
    }, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [section.icon ? (_openBlock(), _createElementBlock("i", {
      key: 0,
      class: _normalizeClass([section.icon, "icon"])
    }, null, 2)) : _createCommentVNode("", true), section.title ? (_openBlock(), _createElementBlock("h3", _hoisted_4, _toDisplayString(section.title), 1)) : _createCommentVNode("", true)]), section.subTitle ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(section.subTitle), 1)) : _createCommentVNode("", true)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.faqs, (faq, questionIndex) => {
      return _openBlock(), _createElementBlock("div", {
        key: faq.question,
        class: _normalizeClass([[{
          'questions--sections--faq--active': $data.expandedSection === sectionIndex && $data.expandedQuestion === questionIndex
        }], "questions--sections--faq"])
      }, [_createElementVNode("h4", {
        class: "questions--sections--faq--question",
        onClick: $event => $options.toggleExpanded(sectionIndex, questionIndex)
      }, [_createElementVNode("span", null, _toDisplayString(faq.question), 1), $data.expandedSection === sectionIndex && $data.expandedQuestion === questionIndex ? (_openBlock(), _createElementBlock("i", _hoisted_7)) : (_openBlock(), _createElementBlock("i", _hoisted_8))], 8, _hoisted_6), _withDirectives(_createElementVNode("p", {
        class: _normalizeClass([[{
          'questions--sections--faq--answer--active': $data.expandedSection === sectionIndex && $data.expandedQuestion === questionIndex
        }], "questions--sections--faq--answer"])
      }, [_createElementVNode("span", {
        innerHTML: faq.answer
      }, null, 8, _hoisted_9)], 2), [[_vShow, $data.expandedSection === sectionIndex && $data.expandedQuestion === questionIndex]])], 2);
    }), 128))]);
  }), 128))]);
}