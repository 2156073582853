import PrivacyPolicy from '@/content/PrivacyPolicy';
import Questions from '@/components/Questions';
export default {
  name: 'PrivacyPolicy',
  components: {
    Questions
  },

  data() {
    return {
      PrivacyPolicy
    };
  }

};