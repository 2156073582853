import TypeWriter from '@/components/TypeWriter';
export default {
  name: 'PartnerHeader',
  components: {
    TypeWriter
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    usps: {
      type: Array,
      required: true
    }
  }
};