import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "partner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_PartnerHeader = _resolveComponent("PartnerHeader");

  const _component_UspCards = _resolveComponent("UspCards");

  const _component_Timeline = _resolveComponent("Timeline");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_PartnerHeader, {
    title: $data.Partner.Carousel.title,
    "sub-title": $data.Partner.Carousel.subTitle,
    usps: $data.Partner.Carousel.usps
  }, null, 8, ["title", "sub-title", "usps"]), _createVNode(_component_UspCards, {
    title: $data.Partner.UspCards.title,
    "sub-title": $data.Partner.UspCards.subTitle,
    "usp-cards": $data.Partner.UspCards.cards
  }, null, 8, ["title", "sub-title", "usp-cards"]), _createVNode(_component_Timeline, {
    title: $data.Partner.Timeline.title,
    "sub-title": $data.Partner.Timeline.subTitle,
    events: $data.Partner.Timeline.events
  }, null, 8, ["title", "sub-title", "events"])]);
}