export default {
  name: 'Questions',
  props: {
    questions: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  data() {
    return {
      expandedSection: -1,
      expandedQuestion: -1
    };
  },

  methods: {
    toggleExpanded(sectionIndex, questionIndex) {
      if (this.expandedSection === sectionIndex && this.expandedQuestion === questionIndex) {
        this.expandedSection = -1;
        this.expandedQuestion = -1;
      } else {
        this.expandedSection = sectionIndex;
        this.expandedQuestion = questionIndex;
      }
    }

  }
};