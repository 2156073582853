import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-23da91b4"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "header"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "sub-title"
};
const _hoisted_4 = {
  class: "type-writer-wrapper"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TypeWriter = _resolveComponent("TypeWriter");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("h1", _hoisted_2, _toDisplayString($props.title), 1), _createElementVNode("h2", _hoisted_3, _toDisplayString($props.subTitle), 1), _createElementVNode("p", _hoisted_4, [_createVNode(_component_TypeWriter, {
    words: $props.usps,
    speed: 80,
    "delete-speed": 30,
    "next-word-interval": 2500
  }, null, 8, ["words"])])]);
}